import styled from 'styled-components';
import { keyframes } from 'styled-components';

const pulseAnimation = keyframes`
    0% { border-color: #9ED104; }
    33% { border-color: #fff; }
    66% { border-color: #9ED104; }
    100% { border-color: #fff; }
`;

export const Container = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0 auto;    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    border: 1.5px solid #9ED104;
    border-radius: 5px;

    @media (max-width: 768px) {
        /* Adjust background positioning and size for mobile */
        background-size: cover;
        background-position: center;
    }
    .things {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 400px;
        width: calc(100% - 30px);
        margin-bottom: 10px;
       

        .content-row {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; 
            text-align: center; 
            padding: 5px 5px;
            background: #1234;
            border: none;
           
            .left {
                font-size: 18px;
                font-weight: bold;
                color: #fff;
                padding: 10px 0;
                
                
            }
            .right {
                font-size: 18px;
                font-weight: bold;
                color: #9ED104;
            }
            p {
                margin: 0;
            }
        } 
      
            button {
                width: 45px;
                border: none;
                cursor: pointer;
                height: 45px;
                font-size: 28px;
                background: transparent;
                transition: all 0.3s ease;
                border-radius: 7px;
                color: whitesmoke;
            }
        }  
        .button-851 {
            color: #9ED104;
            padding: 5px;
            border-radius: 7px;
            border: solid 2px;
            font-weight: bold;
            cursor: pointer;
            text-align: center;
            background: transparent;
            animation: ${pulseAnimation} 2s linear infinite; /* Apply the pulsing animation */
        }    
    }
`;

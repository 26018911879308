import React, { useState } from 'react';
import { Link } from "react-router-dom";
import * as Element from "./style";
import twitterIcon from '../../assets/images/twitterlogo.svg';
import telegram from '../../assets/images/tglogo.svg';
import Discord from '../../assets/images/discord3.svg';
import dextools from '../../assets/images/etherscan.svg';
// import AppStore from '../../assets/images/assets/Untitled design (1).png';
// import Android  from '../../assets/images/assets/andriod.png';
// import AddWallet from '../../components/AddWalletButton';

import Logo from "../../assets/images/MainLogo.svg";

import { useAccount, useDisconnect, useEnsName } from 'wagmi';
import { shorter } from '../../utils';
import { connectorLocalStorageKey } from '../../utils/connectors';

function Header(props) {
  const { connectAccount } = props;

  const { address, isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address });
  
  const [showDropDown, setShowDropDown] = useState(false);

  const { disconnect } = useDisconnect()

  function signOut() {
    disconnect();
    window.localStorage.setItem(connectorLocalStorageKey, "");
  }

  return (
    <Element.Header>
      <Element.Container>
        <Element.HeaderContent>
        <Element.Logo>
            <Link to="/">
              <div className='img-content'>
                  <img src={Logo} alt='Eth Dragon Logo' />
              </div>
            </Link>
        </Element.Logo>

          <Element.ConnectWrapper>
            {
              isConnected ?
                <Element.ButtonContainer>
                  <div className='button-851' onClick={() => {
                      setShowDropDown(!showDropDown);
                    }}>
                    <p>
                    {ensName ?? shorter(address)}                     
                    </p>
                    <i className='icon-angle'></i>
                  </div>
                </Element.ButtonContainer>
                :
                <Element.ButtonContainer>
                  <div className='button-851' onClick={() => { connectAccount() }}>
                    CONNECT WALLET
                  </div>
                </Element.ButtonContainer>
            }
          
            {
              isConnected && showDropDown &&
              (
                <Element.HeaderCenter>
                  <Element.DropDownMenus>
                    <Element.DropDownMenu className='button-851'
                      onClick={() => {
                        signOut();
                        setShowDropDown(false);
                      }}>
                      Disconnect
                    </Element.DropDownMenu>
                  </Element.DropDownMenus>
                </Element.HeaderCenter>
              )
            }
          </Element.ConnectWrapper>
        </Element.HeaderContent>
      </Element.Container>
      {/* Social Icons */}
      <Element.SocialIcons>
                            <a href="https://twitter.com/etherdrago6969?s=21&t=6QMEGCnqvt9oYdYwpUUa8w" target="_blank" rel="noopener noreferrer">
                                <img src={twitterIcon} alt="Twitter" />
                            </a>
                            <a href="https://t.me/etherdrago" target="_blank" rel="noopener noreferrer">
                                <img src={telegram} alt="Telegram" />
                            </a>
                            <a href="https://discord.gg/af8e5HmC" target="_blank" rel="noopener noreferrer">
                                <img src={Discord} alt="Discord" />
                            </a>
                            <a href="https://etherscan.io/address/0x6151303a9904a4ce3472ea8b4b35a24712efd303" target="_blank" rel="noopener noreferrer">
                                <img src={dextools} alt="Etherscan" />
                            </a>
                            {/* <a href="https://apps.apple.com/us/app/odd-app/id1636319934" target="_blank" rel="noopener noreferrer">
                                <img src={AppStore} alt="Apple Store" />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.mycodd.app" target="_blank" rel="noopener noreferrer">
                                <img src={Android} alt="Andriod Play Store" />
                            </a> */}
                            {/* <AddToWalletButton /> */}
                        </Element.SocialIcons>
                        {/* < AddWallet /> */}

    </Element.Header>
  );
}

export default Header;

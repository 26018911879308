import { Contract } from '@ethersproject/contracts';
import { ethers } from "ethers";

import NFTCollectionABI from '../contracts/NFTCollection.json';
// import RewardsContractABI from '../contracts/RewardsContract.json'


export const custom_network = {
  id: Number(process.env.REACT_APP_CHAINID),
  name: process.env.REACT_APP_CHAINNAME,
  network: process.env.REACT_APP_CHAINNETWORK,
  nativeCurrency: {
    decimals: 18,
    name: process.env.REACT_APP_CHAIN_CURRENCY_NAME,
    symbol: process.env.REACT_APP_CHAIN_CURRENCY_SYMBOL,
  },
  rpcUrls: {
    public: { http: [process.env.REACT_APP_RPC] },
    default: { http: [process.env.REACT_APP_RPC] },
  },
  blockExplorers: {
    etherscan: { name: 'Etherscan', url: process.env.REACT_APP_EXPLORE },
    default: { name: 'Etherscan', url: process.env.REACT_APP_EXPLORE },
  },
  contracts: {
    multicall3: {
      address: '0x6151303A9904a4cE3472eA8B4b35a24712EfD303',
      blockCreated: 18903108,
    },
  },
}


export function getPreSaleContract(provider) {
  if (!!provider) {
    return new Contract(process.env.REACT_APP_CONTRACT, NFTCollectionABI, provider);
  } else {   
    const rpcProvider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC);
    return new Contract(process.env.REACT_APP_CONTRACT, NFTCollectionABI, rpcProvider);
  }
}

// export const custom_networkTwo = {
//   id: Number(process.env.REACT_APP_CHAINIDTWO),
//   name: process.env.REACT_APP_CHAINNAMETWO,
//   network: process.env.REACT_APP_CHAINNETWORKTWO,
//   nativeCurrency: {
//     decimals: 18,
//     name: process.env.REACT_APP_CHAIN_CURRENCY_NAMETWO,
//     symbol: process.env.REACT_APP_CHAIN_CURRENCY_SYMBOLTWO,
//   },
//   rpcUrls: {
//     public: { http: [process.env.REACT_APP_RPCTWO] },
//     default: { http: [process.env.REACT_APP_RPCTWO] },
//   },
//   blockExplorers: {
//     etherscan: { name: 'Etherscan', url: process.env.REACT_APP_EXPLORETWO },
//     default: { name: 'Etherscan', url: process.env.REACT_APP_EXPLORETWO },
//   },
//   contracts: {
//     multicall3: {
//       address: '0xC8E7117d95e5130720d194A6e1338BD976632874',
//       blockCreated: 10309490,
//     },
//   },
// }


// export function getRewardsContract(provider) {
//   if (!!provider) {
//     return new Contract(process.env.TOKEN_APP_CONTRACTTWO, provider);
//   } else {   
//     const rpcProvider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPCTWO);
//     return new Contract(process.env.TOKEN_APP_CONTRACTTWO, rpcProvider);
//   }
// }

export const shorter = (str) =>
  str?.length > 8 ? str.slice(0, 4) + '...' + str.slice(-4) : str
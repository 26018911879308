import React from 'react';
import './Roadmap.css';

const Roadmap = () => {
  return (
    <div className="roadmap-container">
      <div className="token1">
        <h2 className="token-title">Ether Drago RoadMap</h2>
      </div>
      {/* Phase 1 */}
      <div className="roadmap-phase">
        <h3>Phase 1: In Progress</h3>
        <ul>
          <li>- Establish <i className='points'>Marketing, Raid & shill team ⏳</i></li>
          <li className='points'>- Establish <i className='points'>TG Community & Discord ⏳</i></li>
          <li className='points'>- Establish <i className='points'>Twitter / X ✅</i></li>
          <li className='points'>- Establish <i className='points'>Website ✅</i></li>
          <li className='points'>- Contract development & deployment ✅</li>
          <li className='points'>- Private Sale ⏳</li>
          <li className='points'><i className='points'>- NFT</i> development & deployment ⏳</li>
        </ul>
      </div>

      {/* Phase 2 */}
      <div className="roadmap-phase">
        <h3>Phase 2: 🔜</h3>
        <ul>
          <li className='points'><i className='points'>- PartnerShips</i> with popular social media influencers</li>
          <li className='points'>- <i></i><i className='points'>Token LOGO</i> on MetaMask <i className='points'>+</i> Trust Wallet</li>
          <li className='points'>- <i className='points'>ERC20 BABY DRAC</i> daily APY <i className='points'>=</i> ETH</li>
          <li className='points'>- Partnerships with <i className='points'>BLUE</i> chip communities</li>
          <li className='points'>- <i className='points'>CMC</i> and <i className='points'>CG</i> listing</li>
        </ul>
      </div>

      {/* Phase 3 */}
      <div className="roadmap-phase">
        <h3>Phase 3: 🔜</h3>
        <ul>
          <li className='points'>- Monthly <i className='points'>NFT</i> auction</li>
          <li className='points'>- <i className='points'>Merchandise</i> store</li>
          <li className='points'>- Launch <i className='points'>blockchain educational</i> platform</li>
        </ul>
      </div>

      {/* Phase 4 */}
      <div className="roadmap-phase">
        <h3>Phase 4: 🔜</h3>
        <ul>
          <li className='points'>- <i className='points'>Exchange</i> listing's</li>
          <li className='points'>- <i className='points'>Dragon NFT</i> marketplace</li>
          <li className='points'>- Collaborate with other communities</li>
          <li className='points'>- <i className='points'>MORE TO COME</i></li>
        </ul>
      </div>
    </div>
  );
};

export default Roadmap;

import React, { useState, useEffect } from 'react';
import { fetchBalance, fetchSigner } from '@wagmi/core';
import { useAccount, useNetwork } from 'wagmi';
import { ethers } from 'ethers';
import toast from 'react-hot-toast';
import { custom_network } from '../../utils';
import * as Element from './style';
import swardImg from '../../assets/images/HappyNewYearTwo.svg';
import Check from '../../assets/images/bluecheck.svg'
import ETH from '../../assets/images/ETH.svg'
import One from '../../assets/images/ONE.svg'
import Twenty from '../../assets/images/Twenty.svg'
import { getTokenInfo, Token } from '../../utils/contracts';
import Tokenonics from '../../components/Roadmap';
import Rewards from '../../components/Rewards';
import UniSwap from '../../components/UniSwap';
import Header from '../header/header';
import "./token.css";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import AddWallet from '../../components/AddWalletButton';

function formatNumberToBillion(number) {
  const billion = 1e9;
  return `${(number / billion).toFixed(1)} Billion`;
}

function TokenContract(props) {
  const { chain } = useNetwork();
  const { address, isConnected, connector: activeConnector } = useAccount();

  const [balance, setBalance] = useState(0);
  useEffect(() => {
    if (address && isConnected && chain && chain.id === custom_network.id) {
      fetchBalance({
        address: address,
      })
        .then((result) => {
          var etherVal = parseFloat(ethers.utils.formatUnits(String(result.value), result.decimals));
          setBalance(etherVal);
        })
        .catch((error) => {
          setBalance(0);
        });
    }
  }, [address, isConnected, chain]);

  const [TokenInfo, setTokenInfo] = useState({
    minEth: 0,
    maxSupply: 0,
    totalSupply: 0,
    totalSupply2: 0,
    time: 0,
    time2: 0,
    name: 0,
    symbol: 0,
    balance: 0,
    tokenPrice: 0,
  });

  useEffect(() => {
    getTokenInfo()
      .then((result) => {
        setTokenInfo(result);
      })
      .catch(() => {
        setTokenInfo({
          minEth: 0,
          maxSupply: 0,
          totalSupply: 0,
          totalSupply2: 0,
          time: 0,
          time2: 0,
          name: 0,
          symbol: 0,
          balance: 0,
          tokenPrice: 0,
        });
      });
  }, []);

  const [ethAmount, setEthAmount] = useState('');
  const [dracAmount, setDracAmount] = useState(0);

  useEffect(() => {
    if (!isNaN(parseFloat(ethAmount))) {
      const ethAmountNumeric = parseFloat(ethAmount);
      const dracAmountCalculated = ethAmountNumeric * 500000000; // 500M DRAC per 1 ETH
      setDracAmount(dracAmountCalculated);
    } else {
      setDracAmount(0);
    }
  }, [ethAmount]);

  // Calculate the percentage for the progress bar
  const preSaleCap = TokenInfo.maxSupply; // This should be your maximum cap for pre-sale
  const mintedInvested = TokenInfo.totalSupply; // This is the current amount minted/invested
  const percentage = (mintedInvested / preSaleCap) * 100;

  async function buyNow() {
    if (address && isConnected && chain && chain.id === custom_network.id) {
      const ethAmountNumeric = parseFloat(ethAmount.replace(/,/g, '')); // Remove commas from the input

      if (isNaN(ethAmountNumeric) || ethAmountNumeric <= 0) {
        toast.error('Please enter a valid and positive ETH amount.');
        return;
      }

      if (ethAmountNumeric < TokenInfo.minEth) {
        toast.error(`Minimum contribution is ${TokenInfo.minEth} ETH.`);
        return;
      }

      if (ethAmountNumeric > 1) {
        toast.error('Maximum contribution is 1 ETH.');
        return;
      }

      if (balance < ethAmountNumeric) {
        toast.error('Insufficient funds!');
        return;
      }

      const load_toast_id = toast.loading(
        'Please wait, your purchase of (DRAC24) Tokens are Being Processed. ***DO NOT REFRESH PAGE***'
      );

      const signer = await fetchSigner();

      Token(ethAmountNumeric, signer)
        .then(async (result) => {
          toast.dismiss(load_toast_id);
          if (result) {
            toast.success('DRAC Token Purchase Success!');
            window.location.reload(); // You may want to remove this line and handle UI updates better
          } else {
            toast.error('Failed to Purchase DRAC Tokens');
          }
        })
        .catch((err) => {
          console.error('error:', err);
          toast.dismiss(load_toast_id);
          toast.error('Failed Transaction');
        });
    }
  }

  return (
    <div>
      <Header {...props} />
      <div className="token1">
      {/* <img src={Check} alt="Team Verify" className="checkmark-icon" /> */}
      <div className="right">0x6151303A9904a4cE3472eA8B4b35a24712EfD303</div>
      </div>
      <div className="token">
        <h2 className="token-title">{TokenInfo.name} ({TokenInfo.symbol})</h2>
      </div>
      <div className="token">
        <h2 className="token-title">PRE-SALE POOL: {TokenInfo.balance} ETH </h2>
      </div>
      <Element.Container>
        <a href="https://babydrac.netlify.app/" target="_blank" rel="noopener noreferrer">
          <div className='button-851'>
            BABY DRAC PRE-SALE EXECUTIVE DIVIDENDS
          </div>
        </a>
      </Element.Container>

      <Element.Container>
        <div className="things">
          <img src={swardImg} alt="Drac24 Logo" className="sward-image"/>
          <div>
            <p className="info"></p>
          </div>
          <div className="content-row">
              <div className="left">
                <p className="price">
                  <img src={One} alt="#1" className="checkmark-icon"/>
                  <img src={ETH} alt="ETH Logo" className="checkmark-icon" /> = {TokenInfo.tokenPrice} / <i>500M DRAC24</i> <br></br>
                  Team Goal: <i><img src={Twenty} alt="#1" className="checkmark-icon"/></i> 
                  <img src={ETH} alt="Team Verify" className="checkmark-icon" />
                </p>
                  
              </div>

              <div className="right">
              <CircularProgressbar
                value={percentage}
                text={`${percentage.toFixed(1)}%`}
                styles={buildStyles({
                  pathColor: `#9ED104`,
                  textColor: '#fff',
                  trailColor: '#222',
                })}
                // Set the size of the circle here
                style={{ width: '80px', height: '80px' }} 
              />
              </div>
            </div>
          <div className="content">
          <div className="content-row">
            <div className="left">
              <img src={Check} alt="Team Verify" className="checkmark-icon" />
              <span className="email-text">Team Email:</span>
            </div>
            <div className="right"><i>etherdragon2024@gmail.com</i></div>
          </div>
            <div className="content-row">
              <div className="left">Pre-Sale CAP:</div>
              <div className="right">
                {formatNumberToBillion(TokenInfo.maxSupply)}
              </div>
            </div>

            <div className="content-row">
              <div className="left">Minimum Buy:</div>
              <div className="right">{TokenInfo.price} ETH </div>
            </div>
        
            <div className="content-row">
              <div className="left">DRAC24 Supply:</div>
              <div className="right">{formatNumberToBillion(TokenInfo.totalSupply2)}</div>
            </div>

            {isConnected && chain && chain.id === custom_network.id && (
              <div className="input-section">
                <div className="content-row">
                  <div className="left">ETH Value:</div>
                  <input
                    type="text" 
                    placeholder={`Minimum ETH ${TokenInfo.minEth}`}
                    value={ethAmount}
                    onChange={(e) => setEthAmount(e.target.value)}
                    min={TokenInfo.minEth} 
                    style={{
                      backgroundColor: '#222',
                      color: '#fff',
                      padding: '10px',
                      borderRadius: '5px',
                    }}
                    inputMode="numeric" // Prevent non-numeric input
                    pattern="[0-9]*" // Allow only digits and dot
                  />
                </div>
                <div className="content-row">
                  <div className="left">You will receive: </div>
                  <div className="right">{dracAmount.toLocaleString()} DRAC24 👈</div>
                </div>
                <div className="button-851" onClick={() => buyNow()}>
                  BUY NOW
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <Home /> */}
      </Element.Container>
      < AddWallet />
      <UniSwap />
      < Rewards />
      <Tokenonics />
    </div>
  );
}

export default TokenContract;

import React from 'react';
import './rewards.css';

const Rewards = () => {
  return (
    <div className="roadmap-container">
      {/* Title Section */}
      <div className="token1">
        <h2 className="token-title">Dragon Executive Dividends</h2>
      </div>
      
      {/* Phase 1 */}
      <div className="roadmap-phase">
        <h3>BABY D TOKEN'OMICS</h3>
        <div className="roadmap-phase p">
        <p>Welcome to "Dragon Executive Dividends," where mythical might meets market mastery! As a valued supporter of our pre-sale, you're set to receive exclusive Baby DRAC tokens, your key to entering the lair of rewards. These aren't just any tokens; they're your ticket to becoming part of an elite circle of Dragon Executives.</p>
        <p>Here's how it unfolds: The moment you supported our pre-sale, you were marked for greatness. Soon, Baby DRAC tokens will soar into your address, ready for action. With these tokens in hand, visit our designated link to stake them and start earning DRAC24 tokens daily. These aren't mere tokens; they're a testament to your foresight, growing in value as they prepare to make their grand entrance on the main market.</p>
        <p>Your investment journey doesn't end here. With the Baby DRAC tokens, you're not just holding a piece of the future; you're actively growing it. Stake your tokens on our platform and watch as your rewards accumulate, ready for you to claim anytime. Your investment has secured you a spot at the ground floor with DRAC24 tokens and an opportunity to amplify your holdings with Baby DRAC.</p>
        <p>But that's not all. Your journey with us is backed by a robust tokenomic structure, ensuring a stable and prosperous ecosystem. With 30% allocated to the Reward Pool, your potential for growth is immense. A dedicated 10% to the Marketing Wallet ensures our dragon's roar reaches far and wide, attracting more to our horde. Another 30% is preserved for the Presale, rewarding those who believed early. Finally, 30% is allocated to Pool to ETH Tokens, maintaining liquidity and stability.</p>
        <p>As a Dragon Executive, you're not just investing; you're leading the charge into a future where your decisions yield real, tangible rewards. Welcome to a realm where your support transforms into your success. Stake your Baby DRAC, earn DRAC24, and watch as your kingdom of rewards expands before your very eyes. The era of Dragon Executive Dividends has begun, and you're at the forefront. Welcome to your destiny.</p>
      </div>
      </div>
    </div>
  );
};

export default Rewards;

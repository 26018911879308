/* eslint-disable no-redeclare */
import { ethers } from "ethers";
import { getPreSaleContract} from ".";

export function isAddress(address) {
    try {
        ethers.utils.getAddress(address);
    } catch (e) { return false; }
    return true;
}

export async function resolveName(name) {
    try {
        const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC);
        var address = await provider.resolveName(name);
        return address;
    } catch (e) { return ''; }    
}

export function toEth(amount, decimal) {
    return ethers.utils.formatUnits(String(amount), decimal);
}

export function toWei(amount, decimal) {
    return ethers.utils.parseUnits(String(amount), decimal);
}


/**
 * collection information
 */

// export async function getMintInfo() {
//     const contract = getMintContract(null);
//     if (!contract) return null;
//     try {
//         const price = await contract._nftPrice();
//         const maxSupply = await contract._maxSupply();
//         const totalSupply = await contract.totalSupply();

//         return {
//             price: toEth(price, 18),
//             maxSupply: Number(maxSupply),
//             totalSupply: Number(totalSupply)
//         }
//     } catch (e) {
//         console.log(e)
//         return null;
//     }
// }


/**
 * mint
*/
// export async function mint(price, amount, provider) {
//     const contract = getMintContract(provider);
//     if (!contract) return false;

//     try {
//         const tx = await contract.mint(amount, {
//             value: toWei(amount*price,18)
//         });
//         await tx.wait(2);
//         return true;
//     } catch (e) {
//         console.log(e);
//         return false;
//     }
// }

// Token Pre Sale Functions

export async function getTokenInfo() {
    const contract = getPreSaleContract(null);
    if (!contract) return null;
    try {
        
        const price = await contract.min_eth();
        const maxSupply = await contract.presale_supply();
        const totalSupply = await contract.sold_supply();
        const totalSupply2 = await contract.TOTAL_SUPPLY();
        const name = await contract.name(); 
        const symbol = await contract.symbol();
        const balance = await contract.getContractBalance();
        const tokenPrice = await contract.tokenPrice();

        return {
            price: toEth(price, 18),
            balance: toEth(balance, 18),
            tokenPrice: toEth(tokenPrice, 18),
            maxSupply: Number(maxSupply),
            totalSupply: Number(totalSupply),
            totalSupply2: Number(totalSupply2),
            name, 
            symbol,
        };
    } catch (e) {
        console.log(e);
        return null;
    }
}

// export async function getContractBalance() {
//     const contract = getPreSaleContract();
//     if (!contract) return null;
//     try {
//         const balance = await contract.getContractBalance();
//         return toEth(balance, 18);
//     } catch (e) {
//         console.error("Error getting contract balance:", e);
//         return null;
//     }
// }



/**
 * Token Sale Buy Button
*/
// contracts.js
// ...

/**
 * Token Sale Buy Button
 */
export async function Token(amount, provider) {
    try {
        // Ensure contract address and ABI are correct
        const contract = getPreSaleContract(provider);
        if (!contract) {
            console.error("Presale contract not found.");
            return false;
        }

        // Convert the 'amount' variable to a string before parsing it
        const ethAmount = ethers.utils.parseEther(amount.toString());

        // Send the transaction to purchase tokens
        const tx = await contract.saleToken({
            value: ethAmount,
        });

        // Wait for the transaction to be mined (you can increase the wait time if needed)
        await tx.wait(2);

        console.log("Tokens purchased successfully.");
        return true;
    } catch (e) {
        console.error("Error purchasing tokens:", e);
        return false;
    }
}


// Reward Token Contract Functions

// export async function getRewardInfo() {
//     const contract = getRewardsContract(null);
//     if (!contract) {
//         console.log("Contract not found or not connected");
//         return null;
//     }
//     try {
//         const tokenPeriod = await contract.rewardPerTokenPerPeriod();
//         console.log(`Token Period from Contract: ${tokenPeriod.toString()}`);

//         return {
//             tokenPeriod: toEth(tokenPeriod, 18),
//         };
//     } catch (e) {
//         console.log("Error fetching data from contract:", e.message);
//         return null;
//     }
// }

// Functions For Rewards Page:






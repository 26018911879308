import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';

import { custom_network } from "./index"

import metamaskIcon from "../assets/images/login/metamask.svg"
import walletconnectIcon from "../assets/images/login/walletconnect.svg";
import coinbaseIcon from "../assets/images/login/coinbase.svg";

export const injectedConnector = new InjectedConnector({ 
  chains: [custom_network],
  options: {
    shimDisconnect: false,
  }, 
});



export const walletConnectConnector = new WalletConnectConnector({
  chains: [custom_network],
  options: {
    projectId: 'ed132e3cde1e8cb08b9332bae447519a',
    isNewChainsStale: false,
  },
});

export const coinbaseWalletConnector = new CoinbaseWalletConnector({
  chains: [custom_network],
  options: {
    appName: 'Baby Drac Pre-Sale Rewards',
    jsonRpcUrl: process.env.REACT_APP_RPC,
  },
});


export function getConnector(key) {
  switch (key) {
    case "injectedConnector":
      return injectedConnector; 
    case "walletConnectConnector":
      return walletConnectConnector;     
    case "coinbaseWalletConnector":
      return coinbaseWalletConnector;    
    default:
      return injectedConnector;
  }
};

export const connectors = [
  {
    title: "Metamask",
    icon: metamaskIcon,
    connectorId: injectedConnector,
    key: "injectedConnector",
  },
  {
    title: "WalletConnect",
    icon: walletconnectIcon,
    connectorId: walletConnectConnector,
    key: "walletConnectConnector",
  },
  {
    title: "Coinbase Wallet",
    icon: coinbaseIcon,
    connectorId: coinbaseWalletConnector,
    key: "coinbaseWalletConnector",
  },
];

export const connectorLocalStorageKey = "connectorId";
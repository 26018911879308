import React from 'react';
import './Roadmap.css';

const UniSwap = () => {
  return (
    <div className="roadmap-container">
      <div className="token1">
        <h2 className="token-title">Ether Dragon Tokenomics</h2>
      </div>
      {/* Phase 1 */}
      <div className="roadmap-phase">
        <h3>DRAC24 TOKEN'OMICS</h3>
        <ul>
          <li>
            The <i className='points'>"EtherDragonToken" </i> contract is a Solidity smart contract developed on the <i className='points'>Ethereum blockchain</i>, created to manage the Ether Drago Token <i className='points'>(DRAC24)</i>. The contract is designed to facilitate the token sale process during a presale event, allowing users to exchange Ether (ETH) for DRAC24 tokens.
          </li>
          <li>
            <strong  className='points'>Main Features and Functionality:</strong>
            <ol>
              <li>
                <strong className='points'>Token Parameters:</strong> The contract initializes several parameters, including the token name ("Ether Dragon"), symbol ("DRAC24"), total supply (224 billion DRAC24 tokens), and the initial token price, where 1 ETH equals 500 million DRAC24 tokens.
              </li>
              <li>
                <strong className='points'>Presale Management:</strong> The contract sets a start and end timestamp for the presale event. Users can only participate in the presale during this period. The contract also tracks the total supply of DRAC24 tokens available for the presale, the amount of tokens sold, and the minimum amount of ETH required for participation.
              </li>
              <li>
                <strong className='points'>Token Purchase:</strong> During the presale, users can send ETH to the contract's "saleToken" function. The contract calculates the number of DRAC24 tokens the user will receive based on the amount of ETH sent and the current token price. If the presale supply is not exhausted, the contract mints and sends the purchased tokens to the user's wallet.
              </li>
              <li>
                <strong className='points'>Presale Completion:</strong> After the presale event ends, the contract owner can trigger the "finishSale" function to finalize the presale. This function handles several actions:
                <ul>
                  <li><i className='points'>Adds liquidity to Uniswap:</i> It creates a trading pair for DRAC24 tokens and ETH on Uniswap and adds liquidity to the newly created pool.</li>
                  <li><i className='points'>Manages remaining tokens:</i>Secured on Contract deployment 60% is in the DEV wallet for the pool after pre-sale / 30% Private Pre-Sale / 10% Marketing Wallet</li>
                  <li><i className='points'>Distributes ETH:</i> The contract splits the ETH balance, sending 80% to provide liquidity on Uniswap and 20% to a specified receiver's address.</li>
                </ul>
              </li>
              <li>
                <strong className='points'>Owner Functions:</strong> The contract owner (deployer) has control over key parameters, such as updating the token price, adjusting the presale timestamps, modifying the presale supply, and changing the receiver address for ETH distribution.
              </li>
            </ol>
          </li>
          <li>
            Overall, the <i className='points'>"EtherDragonTokenTest"</i> contract streamlines the presale process, ensuring that users receive DRAC24 tokens based on their ETH contributions. Additionally, it sets up liquidity on Uniswap and manages the allocation of remaining tokens and ETH.
          </li>
          <li>
            Please note that this is a high-level explanation of the contract's functionality, and users should carefully review the contract code and conduct audits to ensure security and accuracy - NOT Financial Advise!-
          </li>
        </ul>
        <code>
          
        </code>
      </div>
    </div>
  );
};

export default UniSwap;

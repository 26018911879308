// AddToWalletButton.js
import './Chart.css';

import React from 'react';

function AddToWalletButton() {
  const addToWallet = async () => {
    try {
      // Check if MetaMask is installed
      if (window.ethereum && window.ethereum.isMetaMask) {
        // Token details
        const tokenAddress = '0x6151303A9904a4cE3472eA8B4b35a24712EfD303'; // The token contract address
        const tokenSymbol = 'DRAC24'; // The token symbol
        const tokenDecimals = 18; // The token decimals
        const tokenImage = 'https://mintedtunes.mypinata.cloud/ipfs/QmQaAqY1Q5nLz6Eyu6ee9ujVEBCeZU7xVjXiE7PhoWzrSd?_gl=1*10koul8*_ga*NzYzOTY0MTE4LjE2OTcxNTYzMzA.*_ga_5RMPXG14TE*MTcwNDQxMTYxNi4xNi4wLjE3MDQ0MTMxMzguNjAuMC4w'; // A URL for the token logo image

        // Add token to MetaMask
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address: tokenAddress, // The address of the token contract
              symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 characters
              decimals: tokenDecimals, // The number of token decimals
              image: tokenImage, // A string url of the token logo
            },
          },
        });

        if (wasAdded) {
          console.log('Thanks for your interest in DRAC24!');
        } else {
          console.log('DRAC24 has not been added');
        }
      } else {
        console.log('MetaMask Extension is not supported on mobile browser please use PC, or COPY Toke ETH address & add the token manually to your wallet using the custom token feature in the MetaMask wallet. Link to install MetaMask browser extension: https://metamask.io/download.html');
      }
    } catch (error) {
      console.error('Could not add token to wallet', error);
    }
  };

  return (
    <div className="add-token">
    <button onClick={addToWallet} className="button-851">Click me to import your DRAC24 tokens to your wallet of choice</button>
    </div>
  );
}

export default AddToWalletButton;

import styled from 'styled-components';
import angle from "../../assets/images/angle.svg";
import { keyframes } from 'styled-components'; // Import keyframes
// import Dragon from '../../assets/images/Untitled design.svg'


const pulseAnimation = keyframes`
    0% { border-color: red; }
    33% { border-color: orange; }
    66% { border-color: yellow; }
    100% { border-color: red; }
`;


export const Container = styled.div`
    margin 0 auto;
    padding: 0 15px;
`;
export const Logo = styled.div`
    display: flex;
    align-items: center;
    a {
        display: flex;
        align-items: center;
        .img-content {
            margin-left: 15px;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
        }
    }
    img {
        width: 80px;
        height: 80px;
        margin-right: 15px; /* If needed */
    }
`;

export const Header = styled.header`
    padding:5px 0;
    width: 100%;
    top: 0;
    z-index: 10;      
`;
export const HeaderContent = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;    
    > div{
        display:flex;
        align-items: flex-start;
    }
    .fa {
        display: none;
        @media only screen and (max-width: 1200px) {
          display: block;
          margin: 20px;
          cursor: pointer;          
        }
    }
    .header-btn {
        color: #fff;
        font-size: 15px;
        padding: 10px;
        background: #1234;
        border-radius: 15px;
        border: solid 4px #fff;
        font-weight: 700;
        display: inline-block;
        cursor: pointer;
        text-align: center;
        width: 135px;
        text-overflow: ellipsis;
        white-space: nowrap;

        animation: ${pulseAnimation} 2s linear infinite; /* Apply the pulsing animation */
    }
    .address-container {
        display: flex;
        text-align: center;
        flex-wrap: nowrap;
        align-items: center;
        border: solid 4px #403C3C;
        border-radius: 15px;
        color: #fff;
        font-size: 15px;
        line-height: 22px;
        padding: 10px;
        background: black;
        animation: ${pulseAnimation} 2s linear infinite; /* Apply the pulsing animation */
        p {
            margin: 0px;
            font-size: 15px;
        }
    }    
    i.icon-angle {
        margin-left: 10px;
        background-image: url(${angle});
        height: 10px;
        width: 10px;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
    }
`;
export const ConnectWrapper = styled.div`
    flex-direction: column;
    font-weight: 600;
    color: #fff;
    font-size: 15px;
    cursor: pointer;
    div div div {
        &:hover {
            color: red;
        }
    }
    &:hover {
        color: #9ED104;
    }
    @media only screen and (max-width: 1200px) {
        padding: 10px;
    }
    margin-left: auto; /* Push to the right */
`;
export const HeaderCenter = styled.div`
    position: relative;    
`;
export const ButtonContainer = styled.div`
    display:flex;
    align-items:center;
    padding-right:20px;
    position:relative;
    img{
        width: 40px;
        height: 40px;
        border-radius: 20px;
    }
`;
export const DropDownMenus = styled.div`
    font-weight: 600;
    color: #fff;
    background: #1234;
    width: 100px;
    position: absolute;
    box-shadow: rgb(68 68 68) 0px 0px 5px;
    border-radius: 2px;
    z-index: 99;
`;
export const DropDownMenu = styled.div`
    padding 10px 10px;
    cursor: pointer;
    &:hover {
        background-color: #1234;
    }
`;

export const SocialIcons = styled.div`
    
    display: flex;
    flex-direction: row;
    justify-content: center;

    a img {
        padding: 10px 2px;
        width: 70px;
        height: 70px;
    }

    @media only screen and (max-width: 768px) {
        
        
        a img {
            width: 80px; // Adjust to desired mobile size
            height: 80px;
    }
`;
